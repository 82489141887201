import React from 'react'
import Top from './Top'
import Middle from './Middle'
import Bottom from './Bottom'

const Career = () => {
  return (
    <div>
        <Top/>
        <Middle/>
        <Bottom/>
    </div>
  )
}

export default Career